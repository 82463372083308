import React, { useEffect, useState } from "react";
import logo from "./../assets/image/logo.png";
import { get } from "../api";
export default function Projects() {
  const [about, setAbout] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await get.getAboutProject();

        setAbout(response);
      } catch (error) {
        console.error("Ошибка при получении данных:", error);
      }
    };
    fetchData();
  }, []);
  return (
    <div className="container">
      <h1 className="title">О проекте</h1>

      {about.map((el) => (
        <div>
          <div className="projects">
            <h1>{el.title}</h1>
            <p>{el.text}</p>
          </div>
          <div className="site">
            <div className="web-site">
              <h1>{el.title2} </h1>
              <p>{el.text2}</p>
            </div>
            <img className="logo-site" src={el.image} alt="" />
          </div>
        </div>
      ))}
    </div>
  );
}
