import React, { useEffect, useState } from "react";
import logo from "./../assets/image/buginlogolight.png";
import socials from "./../assets/image/socials.png";
import { useNavigate } from "react-router-dom";
import { get } from "../api";
import LiveInternetCounter from "../LiveInternetCounter";
export default function Footer() {
  const navigate = useNavigate();
  const [qualities, setQualities] = useState([]);
  const [contact, setContact] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await get.getContact();

        setQualities(response);
      } catch (error) {
        console.error("Ошибка при получении данных:", error);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await get.getIcons();

        setContact(response);
      } catch (error) {
        console.error("Ошибка при получении данных:", error);
      }
    };

    fetchData();
  }, []);
  return (
    <div id="footer">
      <div className="line">
        <div className="container">
          <div className="footer">
            <div className="logo_footer">
              <div>
                <img className="logo" src={logo} alt="" />
                <p>
                  Бугин.Инфо — читайте, понимаете, обсуждайте на родном языке
                </p>
              </div>
              <LiveInternetCounter />
            </div>
            <div>
              {qualities.map((el) => (
                <div className="contact">
                  <h5>Контакты</h5>
                  <p>{el.address} </p>
                  <a href={`mailto:${el.email}`}>{el.email}</a>
                </div>
              ))}
              <div className="links">
                <h5>Полезные ссылки</h5>
                <p
                  onClick={() => navigate("/projects")}
                  style={{ cursor: "pointer" }}
                >
                  О проекте
                </p>
                <p
                  onClick={() => navigate("/terms")}
                  style={{ cursor: "pointer" }}
                >
                  Условия перепечатки материалов
                </p>
              </div>
            </div>
            <div className="check">
              <h5>Подписаться</h5>

              <div className="fled">
                {contact.map((el) => (
                  <div className="inc">
                    <a href={el.url} target="_blank">
                      <img className="socials" src={el.icon} alt="" />
                    </a>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
