import React from "react";
import { GoPlus } from "react-icons/go";

export default function Terms() {
  return (
    <div className="container">
      <h1 className="title">Условия перепечатки материалов</h1>
      <div className="terms">
        <h4>
          Любой материал, опубликованный "Bugin Info" в форме доступной для
          копирования, может быть скопирован и опубликован на любом другом сайте
          при выполнении следующих условий:
        </h4>
        <div>
          <div className="bus">
            <GoPlus size={50} color="#3E5DD2" />
            <p>
              В опубликованной перепечатке должна быть сохранена информация об
              авторстве и указан источник материалов - "Bugin Info"
            </p>
          </div>
          <div className="bus">
            <GoPlus size={70} color="#3E5DD2" />
            <p>
              Кроме того, "Bugin Info" ничего не имеет против простановки ссылок
              на отдельные статьи, включая формирование на своем сайте целых
              разделов, собранных из таких ссылок
            </p>
          </div>
          <p className="text-terms">
            <span>Не все материалы</span>, размещенные на сайте "Bugin Info"  , являются
            редакционными материалами, "Bugin Info" также размещает материалы
            других СМИ (ссылка на источник всегда указывается). Воспроизведение
            (целиком или частями) таких материалов может производиться только с
            учетом интересов правообладателей.
          </p>
        </div>
      </div>
    </div>
  );
}
