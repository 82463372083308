import React, { useEffect, useState } from "react";
import { IoEyeSharp } from "react-icons/io5";
import { useNavigate, useParams } from "react-router-dom";
import { get } from "../api";
import { FaVk } from "react-icons/fa";
import { SiOdnoklassniki } from "react-icons/si";
import { FaTelegramPlane } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import ScrollToTop from "../ScrollToTop";
import {
  VKShareButton,
  OKShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
} from "react-share";
import { Helmet } from "react-helmet";
export default function DeatilNews({ searchTerm }) {
  const navigate = useNavigate();
  const { slug } = useParams();
  const { lang } = useParams();
  const [russians, setRussians] = useState([]);
  const [popular, setPopular] = useState([]);
  const [contact, setContact] = useState([]);
  const [news, setNews] = useState([]);
  const [displayedItems, setDisplayedItems] = useState(10);

  const handleLoadMore = () => {
    setDisplayedItems((prev) => prev + 10);
  };

  const filterNews = (data) =>
    data.filter((item) =>
      item.title.toLowerCase().includes(searchTerm.toLowerCase())
    );

  useEffect(() => {
    const fetchPopular = async () => {
      try {
        const response = await get.getPopular(lang);
        setPopular(response.results);
      } catch (error) {
        console.error("Ошибка при получении популярных новостей:", error);
      }
    };
    fetchPopular();
  }, [lang]);

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        const response = await get.getDetails(lang, slug);
        setRussians(response);
      } catch (error) {
        console.error("Ошибка при получении данных о новости:", error);
      }
    };
    fetchDetails();
  }, [lang, slug]);

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const response = await get.getNews(lang);
        setNews(response.results);
      } catch (error) {
        console.error("Ошибка при получении последних новостей:", error);
      }
    };
    fetchNews();
  }, [lang]);

  useEffect(() => {
    const fetchIcons = async () => {
      try {
        const response = await get.getIcons();
        setContact(response);
      } catch (error) {
        console.error(
          "Ошибка при получении значков для социальных сетей:",
          error
        );
      }
    };
    fetchIcons();
  }, []);
  // useEffect(() => {
  //   if (russians.length > 0) {
  //     const metaTitle = document.querySelector('meta[property="og:title"]');
  //     const metaDescription = document.querySelector(
  //       'meta[property="og:description"]'
  //     );
  //     const metaImage = document.querySelector('meta[property="og:image"]');
  //     if (metaTitle) metaTitle.content = russians[0].title;
  //     if (metaDescription) metaDescription.content = russians[0].description;
  //     if (metaImage) metaImage.content = russians[0].image;
  //     document.title = russians[0].title;
  //   }
  // }, [russians]);
  useEffect(() => {
    const replaceOembed = () => {
      document.querySelectorAll("oembed[url]").forEach((oembed) => {
        const url = oembed.getAttribute("url");
        const iframe = document.createElement("iframe");
        iframe.src = url.replace("watch?v=", "embed/");
        iframe.width = "100%";
        iframe.height = "360";
        iframe.frameBorder = "0";
        iframe.allow =
          "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture";
        iframe.allowFullscreen = true;
        oembed.replaceWith(iframe);
      });
    };
    replaceOembed();
  }, [russians]);
  const ShareButtons = () => {
    const [image, setImage] = useState(null);
    const [url, setUrl] = useState("");
    const [title, setTitle] = useState("");

    useEffect(() => {
      const metaImage = document
        .querySelector('meta[property="og:image"]')
        ?.getAttribute("content");
      setImage(metaImage || "");
      setUrl(window.location.href);
      setTitle(document.title);
    }, []);

    return (
      <>
        <div
          className="share-buttons"
          style={{ display: "flex", gap: "10px", flexDirection: "column" }}
        >
          <div
            className="share-buttons-icons"
            style={{ display: "flex", gap: "10px", marginTop: "10px" }}
          >
            <VKShareButton url={`${url}`} title={`${title}`}>
              <FaVk color="#0d2e51" size={25} />
            </VKShareButton>

            <OKShareButton url={`${url}`} title={`${title}`}>
              <SiOdnoklassniki color="#0d2e51" size={25} />
            </OKShareButton>

            <TelegramShareButton url={`${url}`} title={`${title}`}>
              <FaTelegramPlane color="#0d2e51" size={25} />
            </TelegramShareButton>

            <TwitterShareButton url={`${url}`} title={`${title}`}>
              <FaTwitter color="#0d2e51" size={25} />
            </TwitterShareButton>

            <WhatsappShareButton url={`${url}`} title={`${title}`}>
              <FaWhatsapp color="#0d2e51" size={25} />
            </WhatsappShareButton>

            <LinkedinShareButton url={`${url}`} title={`${title}`}>
              <FaLinkedinIn color="#0d2e51" size={25} />
            </LinkedinShareButton>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="container">
      {russians.length > 0 && (
        <Helmet>
          <title>{russians[0].title}</title>
          <meta property="og:description" content={russians[0].description} />
          <meta property="og:image" content={russians[0].image} />
        </Helmet>
      )}
      <ScrollToTop searchTerm={searchTerm} />
      {filterNews(russians).map((el) => (
        <div key={el.id} className="details-news">
          <img className="detail_image" src={el.image} alt="" />
          <div className="text_detail">
            <h1>{el.title}</h1>
            <p>{el.created_at}</p>
          </div>
        </div>
      ))}

      <div className="news_section">
        <div className="text-discreption">
          {filterNews(russians).map((el, index) => (
            <div key={index}>
              <p dangerouslySetInnerHTML={{ __html: el.description }}></p>
            </div>
          ))}
          <div className="contacts">
            <p>Поделиться</p>
            {russians.length > 0 && <ShareButtons />}
          </div>
        </div>

        <div className="dat_detail">
          <h1 className="titlee">Популярные новости</h1>
          {filterNews(popular)
            .slice(0, 6)
            .map((el) => (
              <div
                style={{ cursor: "pointer" }}
                key={el.id}
                onClick={() => navigate(`/detail-news/${el.slug}/${lang}`)}
              >
                <img className="popular_image" src={el.image} alt="" />
                <div className="details_eyes">
                  <h5>{el.title}</h5>
                  <div className="dates_ey">
                    <p>{el.created_at}</p>
                    <div className="eyes">
                      <IoEyeSharp size={17} color="#737171" />
                      <p>{el.viewed}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>

      <div>
        <h1 className="title">Последние новости</h1>
        <div className="mba">
          {filterNews(news)
            .slice(0, displayedItems)
            .map((el, index) => (
              <div
                key={index}
                onClick={() => navigate(`/detail-news/${el.slug}/${lang}`)}
                className="newsl"
              >
                <img src={el.image} alt="" />
                <div className="date-title">
                  <h3>{el.title}</h3>
                  <p>{el.created_at}</p>
                </div>
              </div>
            ))}
          {displayedItems < news.length && (
            <div className="load-more">
              <button onClick={handleLoadMore} className="cl">
                Дальше
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
