import { Route, Routes } from "react-router-dom";
import "./App.css";
import "../src/assets/styles/media.css";
import "./assets/styles/font.css";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Section from "./components/Section";
import DeatilNews from "./Pages/DeatilNews";
import Projects from "./Pages/Projects";
import Terms from "./Pages/Terms";
import AllNews from "./Pages/AllNews";
import ScrollToTop from "./ScrollToTop";
import { get } from "./api";
import { useEffect, useState } from "react";
function App() {
  const [searchTerm, setSearchTerm] = useState("");
  useEffect(() => {
    const fetchLogoData = async () => {
      try {
        const response = await get.getLogo();
        const { favicon } = response[0];

        const linkElement = document.querySelector("link[rel='icon']");
        if (linkElement) {
          linkElement.href = favicon;
        }
      } catch (error) {
        console.error("Ошибка при получении данных:", error);
      }
    };

    fetchLogoData();
  }, []);

  return (
    <div className="App">
      <Header searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
      <ScrollToTop />

      <Routes>
        <Route
          path="details/:lang"
          element={<Section searchTerm={searchTerm} />}
        />
        <Route path="/" element={<AllNews searchTerm={searchTerm} />} />
        <Route
          path="detail-news/:slug/:lang"
          element={<DeatilNews searchTerm={searchTerm} />}
        />
        <Route path="projects" element={<Projects />} />
        <Route path="terms" element={<Terms />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
