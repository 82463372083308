import React, { useEffect, useState } from "react";
import logo from "./../assets/image/Bugin Info.png";
import { IoSearchOutline, IoClose } from "react-icons/io5"; // Close иконкасын кошуу
import { useNavigate } from "react-router-dom";
import { get } from "../api";
import kz from "./../assets/image/kz.png";
import uz from "./../assets/image/uz.png";
import tjk from "./../assets/image/tjk.png";
import rus from "./../assets/image/rus.png";
import flag from "./../assets/image/flag.png";
export default function ({ searchTerm, setSearchTerm }) {
  const [uzbeks, setUzbek] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await get.getLogo();

        setUzbek(response);
      } catch (error) {
        console.error("Ошибка при получении данных:", error);
      }
    };

    fetchData();
  }, []);
  const kyrgyzstan = [
    {
      flag: flag,
      title: "Кыргызча жанылыктар",
    },
  ];
  const kazakhztan = [
    {
      flag: kz,
      title: "Казақша жаңалықтар",
    },
  ];
  const uzbek = [
    {
      flag: uz,
      title: "О'zbek tilida yangiliklar",
    },
  ];
  const tajik = [
    {
      flag: tjk,
      title: "Хабархо ба забони точикй",
    },
  ];
  const russian = [
    {
      flag: rus,
      title: "Новости на русском",
    },
  ];
  const countries = [
    ...kyrgyzstan.map((el) => ({ ...el, route: "/details/kg" })),
    ...kazakhztan.map((el) => ({ ...el, route: "/details/kz" })),
    ...uzbek.map((el) => ({ ...el, route: "/details/uz" })),
    ...tajik.map((el) => ({ ...el, route: "/details/tj" })),
    ...russian.map((el) => ({ ...el, route: "/details/ru" })),
  ];

  return (
    <div>
      <div id="header">
        <div className="container">
          <div className="header">
            {uzbeks.map((el) => (
              <img
                onClick={() => navigate("/")}
                style={{ cursor: "pointer" }}
                className="logo"
                src={el.logo}
                alt=""
              />
            ))}
            <div className="search">
              <input
                type="text"
                placeholder="Поиск новостей"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              {searchTerm ? (
                <IoClose
                  color="#0D2E51"
                  size={30}
                  className="icons_search"
                  onClick={() => setSearchTerm("")}
                  style={{ cursor: "pointer" }}
                />
              ) : (
                <IoSearchOutline
                  color="#0D2E51"
                  size={30}
                  className="icons_search"
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="language">
        <div className="container">
          <div className="lang">
            {countries.map((el) => (
              <div
                onClick={() => navigate(el.route)}
                className="flag"
                key={el.title}
              >
                <img src={el.flag} alt={el.title} />
                <p>{el.title}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
