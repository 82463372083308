import React, { useEffect } from "react";

const LiveInternetCounter = () => {
  useEffect(() => {
    const img = document.createElement("img");
    img.id = "licntE3F6";
    img.width = 88;
    img.height = 120;
    img.style.border = "0";
    img.title = "LiveInternet: number of visitors and pageviews is shown";
    img.src =
      "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAEALAAAAAABAAEAAAIBTAA7";
    img.alt = "";

    const link = document.createElement("a");
    link.href = "https://www.liveinternet.ru/click";
    link.target = "_blank";
    link.appendChild(img);

    const script = document.createElement("script");
    script.textContent = `
      (function(d,s){
        d.getElementById("licntE3F6").src=
        "https://counter.yadro.ru/hit?t28.9;r"+escape(d.referrer)+
        ((typeof(s)=="undefined")?"":";s"+s.width+""+s.height+""+
        (s.colorDepth?s.colorDepth:s.pixelDepth))+";u"+escape(d.URL)+
        ";h"+escape(d.title.substring(0,150))+";"+Math.random()
      })(document,screen);
    `;

    const container = document.getElementById("liveinternet-container");
    if (container) {
      container.appendChild(link);
      container.appendChild(script);
    }
  }, []);

  return <div id="liveinternet-container"></div>;
};

export default LiveInternetCounter;
