import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { get } from "../api";
import { IoIosArrowForward } from "react-icons/io";
import ScrollToTop from "../ScrollToTop";

export default function AllNews({ searchTerm }) {
  const navigate = useNavigate();
  const [qualities, setQualities] = useState([]);
  const [kazakhztans, setKazakhztan] = useState([]);
  const [uzbeks, setUzbek] = useState([]);
  const [tajiks, setTajiks] = useState([]);
  const [russians, setRussians] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const allNews = await get.getAllNews();
        const kazakhNews = await get.getAllKazahk();
        const uzbekNews = await get.getAllUzbek();
        const tajikNews = await get.getTajik();
        const russianNews = await get.getRussian();

        setQualities(allNews.results);
        setKazakhztan(kazakhNews.results);
        setUzbek(uzbekNews.results);
        setTajiks(tajikNews.results);
        setRussians(russianNews.results);
      } catch (error) {
        console.error("Ошибка при получении данных:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const filterNews = (news) =>
    news.filter((item) =>
      item.title.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .slice(0, 10);

  if (isLoading) {
    return <div className="loading">Загрузка данных...</div>;
  }

  const renderNews = (news, language) => {
    const displayedNews = isMobile ? news.slice(0, 4) : news;
    return displayedNews.map((el) => (
      <div className="kyrgyz" key={el.id}>
        <div
          onClick={() => navigate(`/detail-news/${el.slug}/${language}`)}
          className="newsl_all"
        >
          <img src={el.image} alt="" />
          <h3>{el.title}</h3>
          <p>{el.created_at}</p>
        </div>
      </div>
    ));
  };

  const renderSection = (news, title, language) => {
    if (news.length > 0) {
      return (
        <>
          <h1 className="news_titles">{title}</h1>
          <div className="newl">
            <div className="center">
              {renderNews(filterNews(news), language)}
            </div>
          </div>
          <button
            className="btn_send"
            onClick={() => navigate(`/details/${language}`)}
          >
            {language === "kg"
              ? "Бардык жаңылыктар"
              : language === "kz"
              ? "Барлық жаңалықтар"
              : language === "uz"
              ? "Barcha yangiliklar"
              : language === "tj"
              ? "Хама хабарҳо"
              : "Все новости"}
            <IoIosArrowForward size={20} color="#fff" />
          </button>
        </>
      );
    }
    return null;
  };

  return (
    <div className="container">
      <ScrollToTop searchTerm={searchTerm} />
      <div className="all_newrs">
        {renderSection(qualities, "Новости Кыргызстана", "kg")}
        {renderSection(kazakhztans, "Новости Казахстана", "kz")}
        {renderSection(uzbeks, "Новости Узбекистана", "uz")}
        {renderSection(tajiks, "Новости Таджикистана", "tj")}
        {renderSection(russians, "Новости России", "ru")}
      </div>
    </div>
  );
}
