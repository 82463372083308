import React, { useEffect, useState } from "react";
import { IoEyeSharp } from "react-icons/io5";
import { useNavigate, useParams } from "react-router-dom";
import { get } from "../api";
import ScrollToTop from "../ScrollToTop";

export default function Section({ searchTerm }) {
  const navigate = useNavigate();
  const { lang } = useParams();
  const [newsItems, setNewsItems] = useState([]);
  const [displayedItems, setDisplayedItems] = useState(10);
  const [popular, setPopular] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const filterNews = (data) =>
    data.filter((item) =>
      item.title.toLowerCase().includes(searchTerm.toLowerCase())
    );

    useEffect(() => {
      const fetchData = async () => {
        setIsLoading(true);
        try {
          const [newsResponse, popularResponse] = await Promise.all([
            get.getNews(lang),
            get.getPopular(lang),
          ]);
          
          let fetchedNews = newsResponse.results;
          let fetchedPopular = popularResponse.results;
    
          if (searchTerm) {
            fetchedNews = filterNews(fetchedNews);
            fetchedPopular = filterNews(fetchedPopular);
          }
    
          setNewsItems(fetchedNews);
          setPopular(fetchedPopular);
        } catch (error) {
          console.error("Ошибка при получении данных:", error);
        } finally {
          setIsLoading(false);
        }
      };
      fetchData();
    }, [lang, searchTerm]);
    
  const handleLoadMore = () => {
    setDisplayedItems((prev) => prev + 10)
  };

  return (
    <div className="container">
       <ScrollToTop searchTerm={searchTerm} />
      <div className="title_detaol">
        <h1 className="title">Последние новости</h1>
        <h3 className="titlee">Популярные новости</h3>
      </div>

      {isLoading ? (
        <p className="loading">Загрузка...</p>
      ) : (
        <div className="news_section">
          <div className="mba">
            {filterNews(newsItems.slice(0, displayedItems)).map((el) => (
              <div
                key={el.id}
                onClick={() => navigate(`/detail-news/${el.slug}/${lang}`)}
                className="newsl"
              >
                <img
                  src={el.image || "/path/to/placeholder.png"}
                  alt={el.title || "Новость"}
                />
                <div className="date-title">
                  <h3>{el.title}</h3>
                  <p className="date">{el.created_at}</p>
                </div>
              </div>
            ))}
             {displayedItems < newsItems.length && (
        <div className="load-more">
          <button onClick={handleLoadMore} className="cl">Дальше</button>
        </div>
      )}
          </div>

          <div className="dat">
            <h3 className="titlee_mobile">Популярные новости</h3>
            {filterNews(popular).slice(0, 5).map((el) => (
              <div key={el.id} onClick={() => navigate(`/detail-news/${el.slug}/${lang}`)}>
                <img
                  className="popular_image"
                  src={el.image || "/path/to/placeholder.png"}
                  alt={el.title || "Популярная новость"}
                />
                <div
                  onClick={() => navigate(`/detail-news/${el.slug}/${lang}`)}
                  style={{ cursor: "pointer" }}
                  className="details_eyes"
                >
                  <h5>{el.title}</h5>
                  <div className="dates_ey">
                    <p>{el.created_at}</p>
                    <div className="eyes">
                      <IoEyeSharp size={17} color="#737171" />
                      <p>{el.viewed}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            
          </div>
        </div>
      )}

     
    </div>
  );
}
