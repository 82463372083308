import axios from "axios";

export const api = axios.create({
  baseURL: "https://bugin.info/api/v1/",
});
export const get = {
  getContact: () => {
    return api
      .get(`contacts/`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error("Error fetching slider items:", error);
        throw error;
      });
  },
  getAllNews: () => {
    return api
      .get(`news_kg/?page_size=10000`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error("Error fetching slider items:", error);
        throw error;
      });
  },
  getAllKazahk: () => {
    return api
      .get(`news_kz/?page_size=10000`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error("Error fetching slider items:", error);
        throw error;
      });
  },
  getAllUzbek: () => {
    return api
      .get(`news_uz/?page_size=10000`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error("Error fetching slider items:", error);
        throw error;
      });
  },
  getTajik: () => {
    return api
      .get(`news_tj/?page_size=10000`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error("Error fetching slider items:", error);
        throw error;
      });
  },
  getRussian: () => {
    return api
      .get(`news_ru/?page_size=10000`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error("Error fetching slider items:", error);
        throw error;
      });
  },
  getSlug: (slug, lang) => {
    return api
      .get(`news_kg/${slug}/?page_size=10000`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error("Error fetching slider items:", error);
        throw error;
      });
  },
  getNews: (lang) => {
    return api
      .get(`news/${lang}/?page_size=10000`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error("Error fetching slider items:", error);
        throw error;
      });
  },
  getDetails: (lang, slug) => {
    return api
      .get(`news/${lang}/${slug}/?page_size=10000`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error("Error fetching slider items:", error);
        throw error;
      });
  },
  getPopular: (lang) => {
    return api
      .get(`/news/${lang}/popular/?page_size=10000`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error("Error fetching slider items:", error);
        throw error;
      });
  },
  getLogo: () => {
    return api
      .get(`logos/`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error("Error fetching slider items:", error);
        throw error;
      });
  },
  getIcons: () => {
    return api
      .get(`social_media/`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error("Error fetching slider items:", error);
        throw error;
      });
  },
  getAboutProject: () => {
    return api
      .get(`about_project/`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error("Error fetching slider items:", error);
        throw error;
      });
  },
};
